<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 948px">
      <div class="layer_head">
        <strong class="tit_popup">계약 정보 불러오기</strong>
      </div>
      <div class="layer_body">
        <PopPurchaseRequestFilter :searchForm="searchForm" @select-connector="getContractInfo" />
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <!--<col style="width: 52px;"> 선택 -->
                <col style="width: 110px" />
                <!-- 계약일 -->
                <col style="width: 80px" />
                <!-- 계약번호 -->
                <col style="width: 200px" />
                <!-- 계약서명 -->
                <col style="width: 140px" />
                <!-- 파트너사 -->
                <col style="width: 135px" />
                <!-- 계약금액 -->
                <col />
                <!-- 구매담당자 -->
              </colgroup>
              <thead>
                <tr>
                  <!--  <th style="width: 52px;">
                    <div class="box_choice type_alone">
                       텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 
                      <div class="item_choice">
                        disabled 상태시 disabled 클래스 추가 
                        <input
                          type="checkbox"
                          id="checkLineAll"
                          class="inp_choice"
                          name="checkLineAll"
                          v-model="checkAll"
                        />
                        <label class="lab_choice" for="checkLineAll">
                          <span class="ico_account ico_check"></span>
                        </label>
                      </div>
                    </div>
                  </th>-->
                  <th style="width: 110px">계약일</th>
                  <th style="width: 80px">계약번호</th>
                  <th style="width: 200px">계약서명</th>
                  <th style="width: 140px">파트너사</th>
                  <th style="width: 135px">계약금액</th>
                  <th style="width: 214px">구매담당자</th>
                </tr>
              </thead>
              <tbody v-if="contractinfoDataList">
                <PopContractinfoListLine
                  v-for="item in contractinfoDataList"
                  :key="item.contractCid"
                  :rowData="item"
                  :selectedContract.sync="selectedContract"
                />
                <!-- <PopContractinfoListLine 
                  v-for="item in contractinfoDataList"
                  :key="item.contractCid"
                  :rowData="item"
                  :checkedNames.sync="checkedNames"/> -->
                <!-- @onClickPurchase="onClickPurchase" -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_secondary"
            :disabled="isDisabledSeletedBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopPurchaseRequestFilter from "./PopPurchaseRequestFilter";
import PopContractinfoListLine from "./PopContractinfoListLine";

import ApiService from "@/services/ApiService";

export default {
  name: "PopContractinfo",
  components: {
    PopPurchaseRequestFilter,
    PopContractinfoListLine,
  },
  props: {
    selectedData: Object,
  },
  data() {
    return {
      searchForm: {
        draftFromDate: "",
        draftToDate: "",
        searchType: { code: "contractCid", name: "계약번호" },
        searchKeyword: "",
      },
      checkAll: false,
      //checkedNames: [],
      selectedContract: {}, // 선택한 계약 데이터
      contractinfoDataList: [],
    };
  },
  computed: {
    isDisabledSeletedBtn() {
      //return this.checkedNames.length === 0;
      return !this.selectedContract.contractCid;
    },
  },
  // 임시
  // watch:{
  //   checkedNames(){
  //     if(this.checkedNames.length === this.contractinfoDataList.length){
  //       this.checkAll = true;
  //     }else if(this.checkAll && this.checkedNames.length != this.contractinfoDataList.length){
  //       this.checkAll = false;
  //     }
  //   },
  //   checkAll(to,from){
  //     // cheked on
  //     if( to && !from ){
  //       if(this.checkedNames.length != this.contractinfoDataList.length){
  //         this.checkedNames = this.contractinfoDataList;
  //       }
  //     }
  //   },
  // },
  mounted() {
    this.selectedContract = this.selectedData;
    this.getInitContractInfo();
  },
  methods: {
    getInitContractInfo() {
      // let obj = {};
      // obj.requestCid = '';
      // obj.contractCid = '';

      // this.getContractInfo(obj);
      this.getContractInfo();
    },
    async getContractInfo() {
      let path = `${this.$apiPath.SETTLEMENT_CONTRACT}`;
      if (this.searchForm.draftFromDate) {
        path += `?startDate=${this.searchForm.draftFromDate}`;
      }
      if (this.searchForm.draftEndDate) {
        path += `&endDate=${this.searchForm.draftEndDate}`;
      }
      if (this.searchForm.searchKeyword) {
        path += `&searchType=${this.searchForm.searchType.code}&searchValue=${this.searchForm.searchKeyword}`;
      }

      const result = await ApiService.shared.getData(path);

      if (result.data) {
        this.contractinfoDataList = result.data;
      }
    },
    onClickSelected() {
      // this.$emit('onClickComplete' , this.checkedNames);
      this.$emit("onClickComplete", this.selectedContract);
    },
  },
};
</script>
